import { useEffect, useRef, useState } from "react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Link from "next/link";
import axios from "axios";
import Autoplay from "embla-carousel-autoplay";
import { cn } from "lib/utils";

import { Page, Header, Section, Button, Image, Breadcrumb, Reviews, Card, Markdown, AnimatedLogo } from "@/components";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/ui/carousel";

import {
  tinaClient,
  useTina,
  tinaField,
  useGetCollection,
  fetchReviews,
  ANIMATED_CONTAINER,
  ANIMATED_ITEM,
} from "@/utils";
import { useWindowSize } from "@uidotdev/usehooks";

export default function Home({ homeQuery, reviews, blogListQuery, globalData }) {
  const { data } = useTina({
    ...homeQuery,
  });

  const { header, opening, treatmentsCTA, blogCTA, cta, partnerships, seo } = data.home;
  const { treatments } = globalData;

  return (
    <Page
      className="[&>section]:mt-16 [&>section:first-of-type]:mt-12 lg:[&>section]:mt-28 lg:[&>section:first-of-type]:mt-16 overflow-hidden"
      seo={seo}
    >
      <Header header={{ ...header, video: "/opening-video4.mp4" }} variant="home" />
      <Opening opening={opening} />
      <Treatments treatmentsCTA={treatmentsCTA} treatments={treatments} />
      <Reviews reviews={reviews} />
      <Articles blogCTA={blogCTA} postsData={blogListQuery?.data} />
      <CataractsCTA cta={cta} />
      <Partners partnerships={partnerships} />
    </Page>
  );
}

function Opening({ opening }) {
  const paragraph = opening?.paragraph;
  const foundWords = "The Eye Unit";
  const pre = paragraph.substring(0, paragraph.indexOf(foundWords));
  const post = paragraph.substring(paragraph.indexOf(foundWords) + foundWords.length);

  return (
    <Section
      wrapperClassName="relative hidden lg:block"
      className="text-center flex flex-col items-center space-y-4 lg:space-y-8"
      animate={true}
    >
      <motion.h2 className="h3 border-b-primary" data-tina-field={tinaField(opening, "title")} variants={ANIMATED_ITEM}>
        {opening?.title}
      </motion.h2>
      <motion.p
        className="mx-auto max-w-[1000px] text-xl md:text-3xl lg:text-4xl font-title text-primary !leading-relaxed"
        data-tina-field={tinaField(opening, "paragraph")}
        variants={ANIMATED_ITEM}
      >
        {pre}
        <strong className="capitalize font-bold italic">{foundWords}</strong>
        {post}
      </motion.p>
      <AnimatedLogo className="absolute -top-12 lg:top-0 right-0 translate-x-1/2 hidden md:block md:w-60 md:h-60 lg:w-80 lg:h-80 mx-auto text-primary" />
    </Section>
  );
}

function Treatments({ treatmentsCTA, treatments }) {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const [api, setApi] = useState();

  let size = {};

  if (typeof window !== "undefined") {
    size = useWindowSize();
  }

  const play = () => {
    if (!api?.plugins().autoplay.isPlaying() && isInView && size.width >= 768) {
      api?.plugins().autoplay?.play();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!api?.plugins().autoplay.isPlaying() && isInView && size.width >= 768) {
        play();
      } else if (api?.plugins().autoplay.isPlaying() && !isInView) {
        api?.plugins().autoplay?.stop();
      }
    }
  }, [isInView, size.width]);

  return (
    <Section wrapperClassName="max-lg:!mt-8 lg:-mb-12" className="space-y-6 md:space-y-12" animate={false}>
      <motion.div
        className="flex flex-col md:flex-row justify-between gap-2"
        ref={ref}
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="space-y-3">
          <motion.h2 className="h1" data-tina-field={tinaField(treatmentsCTA, "title")} variants={ANIMATED_ITEM}>
            {treatmentsCTA?.title}
          </motion.h2>
          <motion.p
            className="p-lg max-w-[640px]"
            data-tina-field={tinaField(treatmentsCTA, "subtitle")}
            variants={ANIMATED_ITEM}
          >
            {treatmentsCTA?.subtitle}
          </motion.p>
        </div>
        <motion.div className="md:mt-2" variants={ANIMATED_ITEM}>
          <Button variant="outline" asChild className="hidden md:block">
            <Link href="/treatments" aria-label="View All Treatments">
              View All Treatments
            </Link>
          </Button>
          <Button variant="link" asChild className="block md:hidden">
            <Link href="/treatments" aria-label="View All Treatments">
              View All Treatments
            </Link>
          </Button>
        </motion.div>
      </motion.div>
      <motion.div
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        variants={ANIMATED_CONTAINER}
      >
        <Carousel
          setApi={setApi}
          className="!overflow-visible [&>div]:!overflow-visible cursor-grab active:cursor-grabbing"
          opts={{
            align: "start",
            skipSnaps: true,
          }}
          plugins={[
            Autoplay({
              playOnInit: false,
              delay: 4000,
              stopOnMouseEnter: true,
              stopOnLastSnap: true,
            }),
          ]}
          onMouseLeave={() => {
            play();
          }}
        >
          <CarouselContent className="!overflow-visible">
            {treatments?.treatmentList?.map(({ treatment }, index) => (
              <CarouselItem key={index} className="basis-[95%] sm:basis-[50%] lg:basis-[33%]">
                <motion.div variants={ANIMATED_ITEM} className="h-full">
                  <Card
                    className="h-full flex"
                    href={`/treatments/${treatment?._sys.filename}`}
                    title={treatment?.header?.title}
                    image={treatment?.header?.image}
                    imageSize={{
                      width: 410,
                      height: 256,
                    }}
                    description={treatment?.header?.subtitle}
                    button={{
                      label: "View Treatment",
                      link: `/treatments/${treatment?._sys.filename}`,
                    }}
                  />
                </motion.div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="mt-4 lg:mt-8 relative flex gap-4">
            <CarouselPrevious className="!relative translate-y-0" />
            <CarouselNext className="!relative translate-y-0" />
          </div>
        </Carousel>
      </motion.div>
    </Section>
  );
}

function Articles({ postsData, blogCTA }) {
  let blogPostsToShow = useGetCollection(postsData?.postConnection);

  blogPostsToShow = blogPostsToShow.slice(0, 3);
  return (
    <Section wrapperClassName="overflow-hidden " className="space-y-6 md:space-y-12">
      <motion.div
        className="flex flex-col md:flex-row justify-between gap-2"
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="space-y-3">
          <motion.h2 className="h1" data-tina-field={tinaField(blogCTA, "title")} variants={ANIMATED_ITEM}>
            {blogCTA?.title}
          </motion.h2>
          <motion.p
            className="p-lg max-w-[680px]"
            data-tina-field={tinaField(blogCTA, "subtitle")}
            variants={ANIMATED_ITEM}
          >
            {blogCTA?.subtitle}
          </motion.p>
        </div>
        <motion.div className="md:mt-2" variants={ANIMATED_ITEM}>
          <Button variant="outline" asChild className="hidden md:block">
            <Link href="/blog" aria-label="View All Articles">
              View All Articles
            </Link>
          </Button>
          <Button variant="link" asChild className="block md:hidden">
            <Link href="/blog" aria-label="View All Articles">
              View All Articles
            </Link>
          </Button>
        </motion.div>
      </motion.div>
      <motion.div
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        variants={ANIMATED_CONTAINER}
      >
        <Carousel
          className="!overflow-visible [&>div]:!overflow-visible"
          opts={{
            breakpoints: {
              "(min-width: 1024px)": {
                active: false,
              },
            },
            align: "start",
            skipSnaps: true,
          }}
        >
          <CarouselContent className="!overflow-visible">
            {blogPostsToShow.map((post, index) => {
              return (
                <CarouselItem className="basis-[95%] sm:basis-[50%] lg:basis-[33.333%]" key={index}>
                  <motion.div key={index} variants={ANIMATED_ITEM} className="h-full">
                    <Card
                      href={`/blog/${post?._sys.filename}`}
                      title={post?.title}
                      image={post?.image}
                      description={post?.summary}
                      imageSize={{
                        width: 410,
                        height: 256,
                      }}
                      button={{
                        label: "Read Article",
                        link: `/blog/${post?._sys.filename}`,
                      }}
                    />
                  </motion.div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <div className="mt-4 relative flex gap-4 lg:hidden">
            <CarouselPrevious className="!relative translate-y-0" />
            <CarouselNext className="!relative translate-y-0" />
          </div>
        </Carousel>
      </motion.div>
    </Section>
  );
}

function CataractsCTA({ cta }) {
  const AnimatedButton = motion.create(Button);

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <Section className="">
      <motion.div
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        variants={ANIMATED_ITEM}
        className="bg-secondary-400 flex flex-col lg:flex-row items-center gap-8 md:gap-12 lg:gap-16 p-4 md:p-12 lg:p-16 lg:ml-16 rounded-lg"
      >
        <motion.div ref={ref} className="flex-1 lg:-ml-32 self-stretch max-lg:!translate-y-0" style={{ y }}>
          <Image
            src={cta?.image}
            alt={cta?.title}
            width={574}
            height={520}
            className="w-full h-full min-h-[280px] sm:min-h-[420px] md:min-h-[480px] lg:min-h-[520px] rounded-lg"
          />
        </motion.div>
        <div className="flex-1 flex flex-col gap-6">
          <div className="space-y-4">
            <motion.h2 className="h2" data-tina-field={tinaField(cta, "title")} variants={ANIMATED_ITEM}>
              {cta.title}
            </motion.h2>
            <motion.div data-tina-field={tinaField(cta, "description")} variants={ANIMATED_ITEM}>
              <Markdown content={cta.description} />
            </motion.div>
          </div>
          <div className="flex flex-col md:flex-row gap-4">
            <AnimatedButton asChild variants={ANIMATED_ITEM}>
              <Link href={cta?.button?.link} aria-label={cta?.button?.label}>
                {cta?.button?.label}
              </Link>
            </AnimatedButton>
            <AnimatedButton asChild variant="outline" variants={ANIMATED_ITEM}>
              <Link href={cta?.buttonTwo?.link} aria-label={cta?.buttonTwo?.label}>
                {cta?.buttonTwo?.label}
              </Link>
            </AnimatedButton>
          </div>
        </div>
      </motion.div>
    </Section>
  );
}

function Partners({ partnerships }) {
  return (
    <Section wrapperClassName="bg-primary-200">
      <motion.div
        className="py-10 space-y-6"
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        variants={ANIMATED_CONTAINER}
      >
        <motion.div variants={ANIMATED_ITEM} className="uppercase text-center font-semibold text-lg ">
          {partnerships?.title}
        </motion.div>
        <Carousel
          className="w-full cursor-grab active:cursor-grabbing user-select-none"
          opts={{
            align: "start",
            skipSnaps: true,
            loop: true,
          }}
          plugins={[
            Autoplay({
              delay: 4000,
            }),
          ]}
        >
          <CarouselContent className="flex items-center">
            {partnerships?.partners.map((partner, index) => (
              <CarouselItem
                key={index}
                className="basis-[50%] md:basis-[30%] lg:basis-[20%]"
                data-tina-field={tinaField(partnerships, `partners.${index}.logo`)}
              >
                <motion.a
                  variants={ANIMATED_ITEM}
                  className="p-1 flex flex-col items-center justify-center"
                  href={partner?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={partner?.logo}
                    alt={partner?.name}
                    className={cn(
                      "w-full h-10 !object-contain",
                      partner?.name === "Circle Health Group" && "!h-14",
                      partner?.name === "ESCRS - The European Society of Cataract and Refractive Surgeons" && "!h-9",
                      partner?.name === "American Academy of Ophthalmology" && "!h-12"
                    )}
                    width={234}
                    height={40}
                  />
                </motion.a>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </motion.div>
    </Section>
  );
}

export const getStaticProps = async () => {
  const homeQuery = await tinaClient.queries.home({
    relativePath: "home.mdx",
  });

  let filter = { published: { eq: true } };

  // if (preview) {
  //   filter = {};
  // }

  // if (isPreview || process.env.NODE_ENV === "development") {filter = {}; }
  const blogListQuery = await tinaClient.queries.blogList({
    filter: filter,
    first: 3,
  });

  const reviews = await fetchReviews();

  return {
    props: {
      homeQuery,
      blogListQuery,
      reviews,
    },
    revalidate: 60,
  };
};
